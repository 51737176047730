import { sanityFetch } from "@/cms/sanity.fetch";
import { SanityReference } from "next-sanity";
import { CMSLink, LinkProps } from "./CMSLink";
import { Link as LinkType } from "../types";
import { linkBlockQuery, linkRevalidateTags } from "@/portable-text/Link/query";
import groq from "groq";
import { LivePreview } from "..";

export type LinkBlockProps = SanityReference &
  Omit<LinkProps, "data"> & {
    variant?: "custom" | LinkProps["data"]["variant"];
  };

const query = groq`*[_id == $id][0]{${linkBlockQuery}}`;

export const SanityLink = async ({
  _ref: id,
  variant,
  ...rest
}: LinkBlockProps) => {
  if (!id) return null;
  const linkConfig = await sanityFetch<LinkType>({
    query: query,
    params: {
      id,
    },
    tags: [id, ...linkRevalidateTags],
  });
  return (
    <LivePreview query={query} initialData={linkConfig} params={{ id }}>
      <CMSLink
        data={variant ? { ...linkConfig, variant } : linkConfig}
        {...rest}
      />
    </LivePreview>
  );
};
