/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import classNames from "classnames";
import { BackgroundStyle } from "../types";
import { isPrioritizedSection } from "../utils";
import { SanityImage } from "@/cms/images/types";
import { Card } from "@cappelendamm/design-system";
import { getThemeClassName } from "@/theming/selectors";
import { SectionProps } from "../Section";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { BlockContainerImage } from "./BlockContainerImage";
import { OverridableComponent } from "@/components/types";

type ImageContentSectionProps = Partial<{
  contentAlignment: "left" | "right";
  background: BackgroundStyle;
  image?: SanityImage;
}> &
  Pick<SectionProps, "index">;

export const ImageContentSectionCard: OverridableComponent<
  ImageContentSectionProps & JSX.IntrinsicElements["section"]
> = ({
  background,
  contentAlignment,
  className,
  style,
  children,
  as = "section",
  index,
  ...rest
}) => {
  const imageFirst = contentAlignment === "left";

  return (
    <BlockContainerImage
      {...rest}
      background={background}
      as={as}
      className="app-grid mx-auto min-h-[541px] items-end sm:min-h-[432px] sm:items-center"
      priority={isPrioritizedSection({ index })}
    >
      <Card
        className={classNames(
          "p-content z-10 col-span-full text-center sm:col-span-3 sm:text-left md:col-span-5",
          {
            [getThemeClassName("primary")]: background?.variant === "color",
            "sm:pl-content sm:col-end-3 md:col-end-6": !imageFirst,
            "sm:pr-content sm:col-end-7 md:col-end-13": imageFirst,
          },
          className,
        )}
        style={{
          ...getCSSPropertiesTheme(
            background?.variant === "color" ? background?.theme : undefined,
          ),
          ...style,
        }}
      >
        {children}
      </Card>
    </BlockContainerImage>
  );
};
