/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import classNames from "classnames";
import { BackgroundStyle } from "../types";
import { SanityImage } from "@/cms/images/types";
import { SectionProps } from "../Section";
import { BlockContainerImage } from "./BlockContainerImage";
import { OverridableComponent } from "@/components/types";
import { Card } from "@cappelendamm/design-system";

type ImageContentSectionProps = Partial<{
  contentAlignment: "left" | "right";
  background: BackgroundStyle;
  image?: SanityImage;
  isFirstOrLastSlide?: boolean;
}> &
  Pick<SectionProps, "index">;

export const ImageContentSectionCentered: OverridableComponent<
  ImageContentSectionProps & JSX.IntrinsicElements["section"]
> = ({
  background,
  contentAlignment,
  className,
  children,
  isFirstOrLastSlide,
  as = "section",
  ...rest
}) => {
  return (
    <BlockContainerImage
      {...rest}
      as={as}
      background={background}
      className={classNames(
        `grid w-full items-end ${
          isFirstOrLastSlide ? "justify-left" : "justify-center"
        }`,
        className,
      )}
    >
      <Card className="prose z-10 md:text-center [&>*]:w-fit [&>*]:p-2 md:[&>*]:mx-auto [&>p]:leading-10">
        {children}
      </Card>
    </BlockContainerImage>
  );
};
