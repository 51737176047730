import groq from "groq";

export const linkBlockQuery = groq`
    title,
    type,
    type == "product" => {
        "slug": book->slug.current,
    },
    type == "book" => {
        "slug": book->slug.current,
    },
    type == "subscription" => {
        "slug": subscription->slug.current,
    },
    type == "contributor" => {
        "slug": contributor->slug.current,
    },
    type == "page" => {
        "slug": page->slug.current,
    },
    type == "article" => {
        "slug": article->slug.current,
    },
    external,
    cartItems[]->{
        "sku": _id,
        _type
    },
    contentAlignment,
    icon,
    variant,
    theme->{
        background,
        text
    },
    universe->{
        "tag": tag.current
    }`;

export const linkRevalidateTags = [
  "book",
  "subscription",
  "contributor",
  "page",
  "article",
];
