"use client";

import Image from "next/image";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxProps,
} from "@headlessui/react";
import React, { forwardRef, useEffect, useState } from "react";
import { getThemeClassName } from "@/theming/selectors";
import classNames from "classnames";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import { SanityImage } from "@/cms/images/types";
import { Price } from "@/products/types";
import { CaretDown } from "@phosphor-icons/react/dist/ssr";
import { ProductVariantList } from "@/cms/types/objects";
import { Themes } from "@/theming/types";
import { Check, Info, X } from "@phosphor-icons/react";

export type SelectorItem = {
  key: string;
  title: string | number;
  subTitle: string | number;
  price?: Price;
  image?: SanityImage;
  slug?: string;
  disabled?: boolean;
  additionalInfo?: string;
};

type ProductVariantSelectProps = {
  subscription?: boolean;
  theme?: Themes;
  listboxButtonClassName?: string;
} & ProductVariantList;

export const ProductVariantSelect = forwardRef<
  HTMLDivElement,
  ProductVariantSelectProps &
    ListboxProps<"div", SelectorItem, SelectorItem> &
    Pick<JSX.IntrinsicElements["div"], "className">
>(
  (
    {
      items,
      className,
      subscription,
      theme,
      value,
      listboxButtonClassName,
      ...listBoxProps
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedValue = value ?? items[0];
    const hasMoreThanOneFormat = items.length > 1;

    const handleItemClick = (item: SelectorItem) => {
      if (!listBoxProps.onChange) {
        return;
      }
      setIsOpen(false);
      listBoxProps.onChange(item);
    };

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, [isOpen]);

    return (
      <Listbox
        value={value}
        onChange={(newValue) => {
          handleItemClick(newValue);
        }}
        {...listBoxProps}
        ref={ref}
        className={classNames("relative flex", className)}
      >
        {({ open }) => (
          <>
            <ListboxButton
              onClick={
                hasMoreThanOneFormat ? () => setIsOpen(!isOpen) : undefined
              }
              className={classNames(
                `grid max-h-80 w-full cursor-auto gap-4 rounded border bg-white px-4 py-2 ${
                  hasMoreThanOneFormat && "cursor-pointer"
                }`,
                listboxButtonClassName,
                getThemeClassName(theme),
              )}
            >
              <div className="flex items-center justify-between gap-3 text-black">
                <div className="flex gap-3">
                  <div>
                    {selectedValue?.image && (
                      <Image
                        {...getImageProps({
                          ...selectedValue.image,
                          ...getImageAspectRatio({
                            height: 70,
                            aspectRatio:
                              selectedValue.image.asset.metadata.dimensions
                                .aspectRatio,
                          }),
                        })}
                        className="w-fit min-w-[42px] rounded-sm"
                      />
                    )}
                  </div>
                  <span className="grid self-center text-left">
                    <span className="font-semibold">
                      {selectedValue?.title}
                    </span>
                    <span className="text-sm text-gray-800">
                      {selectedValue?.subTitle}
                    </span>
                  </span>
                </div>
                <div>
                  {hasMoreThanOneFormat && (
                    <span className="flex w-full items-center gap-2 text-left">
                      <CaretDown size={24} className="my-3 self-center" />
                    </span>
                  )}
                </div>
              </div>
            </ListboxButton>
            {open && (
              <div className="fixed inset-0 z-50 !m-0 overflow-y-auto bg-black bg-opacity-80">
                <div className="fixed inset-x-0 inset-y-80 bottom-0 m-2 max-h-[80vh] overflow-y-auto rounded bg-white shadow-lg md:m-4">
                  <div className="p-4 sm:p-8">
                    <div className="flex items-center justify-between">
                      <span className="text-elevated-contrast text-center font-medium">
                        Velg produkt
                      </span>
                      <ListboxButton
                        onClick={() => setIsOpen(false)}
                        className="rounded text-gray-800"
                      >
                        <X className="inline" size={25} />
                      </ListboxButton>
                    </div>
                    <ListboxOptions as="ul" className="mt-4 grid gap-4">
                      {items.map((item) => (
                        <ListboxOption
                          key={item.key}
                          className={classNames(
                            "flex items-center rounded-xl bg-white",
                            "px-3 py-3 md:px-6",
                            "hover:cursor-pointer hover:bg-[#F2EFF0]",
                            "data-[selected]:bg-[#C9F2BF]",
                            "data-[disabled]:!bg-white data-[disabled]:opacity-50 data-[disabled]:hover:cursor-not-allowed",
                          )}
                          value={item}
                          onClick={() => handleItemClick(item)}
                          disabled={item.disabled}
                        >
                          {({ selected }) => (
                            <div className="flex w-full items-center justify-between">
                              <div className="flex items-center gap-3">
                                {item.image && (
                                  <Image
                                    {...getImageProps({
                                      ...item.image,
                                      ...getImageAspectRatio({
                                        height: 70,
                                        aspectRatio:
                                          item.image.asset.metadata.dimensions
                                            .aspectRatio,
                                      }),
                                    })}
                                    className="w-fit min-w-[42px] rounded-sm"
                                  />
                                )}
                                <span className="grid self-center text-left">
                                  <span className="text-elevated-contrast font-semibold">
                                    {item.title}
                                  </span>
                                  <span className="text-sm text-gray-800">
                                    {item.subTitle}
                                  </span>
                                  {item.additionalInfo && (
                                    <span className="flex items-center gap-1 text-sm">
                                      <Info size={16} />
                                      {item.additionalInfo}
                                    </span>
                                  )}
                                </span>
                              </div>
                              {selected && (
                                <Check size={24} className="self-center" />
                              )}
                            </div>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Listbox>
    );
  },
);

ProductVariantSelect.displayName = "ProductVariantSelect";
