/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Image from "next/image";
import classnames from "classnames";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import classNames from "classnames";
import { BackgroundStyle } from "../types";
import { isPrioritizedSection } from "../utils";
import { BlockContainer } from "./BlockContainer";
import { SanityImage } from "@/cms/images/types";
import { SectionProps } from "../Section";
import { OverridableComponent } from "@/components/types";
import { SanityLink } from "@/cms/links/components";
import Link from "next/link";

type ImageContentSectionProps = Partial<{
  contentAlignment: "left" | "right";
  background: BackgroundStyle;
  image?: SanityImage;
  shortSection?: boolean;
  imageClassnames: string;
  link: { _ref: string; _type?: string } | string;
}> &
  Pick<SectionProps, "index">;
export const ImageContentSectionFlat: OverridableComponent<
  ImageContentSectionProps & JSX.IntrinsicElements["section"]
> = ({
  index,
  background,
  image,
  contentAlignment,
  className,
  imageClassname,
  link,
  style,
  children,
  shortSection,
  as = "section",
  ...rest
}) => {
  const imageFirst = contentAlignment === "left";
  return (
    <BlockContainer {...rest} {...background} as={as}>
      <div
        className={classNames("app-grid", {
          "mx-auto": background?.container === "fluid",
        })}
      >
        {image && (
          <div
            className={classnames(
              "relative col-span-full m-[auto] aspect-[4/3] w-full sm:col-span-3",
              imageFirst
                ? "md:col-start-1 md:col-end-7"
                : "sm:order-last md:col-start-7 md:col-end-13",
            )}
          >
            {link ? (
              typeof link === "string" ? (
                <Link href={link}>
                  {image && (
                    <Image
                      {...getImageProps({
                        ...image,
                        ...getImageAspectRatio({
                          height: 1140 / 2,
                          aspectRatio: "4/3",
                        }),
                      })}
                      fill
                      width={undefined}
                      height={undefined}
                      priority={isPrioritizedSection({ index })}
                      className={classNames("object-cover", imageClassname)}
                    />
                  )}
                </Link>
              ) : (
                <SanityLink
                  parentIndex={rest.index}
                  parentSection={rest._type}
                  itemIndex={0}
                  _ref={link?._ref}
                  className="contents text-[0px] [&>:not(img)]:hidden"
                >
                  {image && (
                    <Image
                      {...getImageProps({
                        ...image,
                        ...getImageAspectRatio({
                          height: 1140 / 2,
                          aspectRatio: "4/3",
                        }),
                      })}
                      fill
                      width={undefined}
                      height={undefined}
                      priority={isPrioritizedSection({ index })}
                      className={classNames("object-cover", imageClassname)}
                    />
                  )}
                </SanityLink>
              )
            ) : (
              image && (
                <Image
                  {...getImageProps({
                    ...image,
                    ...getImageAspectRatio({
                      height: 1140 / 2,
                      aspectRatio: "4/3",
                    }),
                  })}
                  fill
                  width={undefined}
                  height={undefined}
                  priority={isPrioritizedSection({ index })}
                  className={classNames("object-cover", imageClassname)}
                />
              )
            )}
          </div>
        )}
        <div
          className={classNames(
            "px-content py-content col-span-full self-center sm:col-span-3 sm:px-0 md:col-span-6 md:ml-12",
            imageFirst ? "sm:pr-content" : "sm:pl-content",
            className,
          )}
          style={style}
        >
          {children}
        </div>
      </div>
    </BlockContainer>
  );
};
