/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Image, { ImageProps } from "next/image";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import classNames from "classnames";
import { BackgroundStyle } from "../types";
import { BlockContainer } from "./BlockContainer";
import { SanityImage } from "@/cms/images/types";
import { OverridableComponent } from "@/components/types";
import { SanityLink } from "@/cms/links/components";

type ImageContentSectionProps = Partial<{
  background: BackgroundStyle;
  image?: SanityImage;
}> &
  Pick<ImageProps, "priority">;

export const BlockContainerImage: OverridableComponent<
  ImageContentSectionProps & JSX.IntrinsicElements["section"]
> = ({
  priority,
  background,
  image,
  className,
  children,
  link,
  as: Component = "section",
  ...rest
}) => {
  return (
    <BlockContainer
      {...background}
      {...rest}
      as={Component}
      className={classNames(
        "min-h-section-sm p-section relative grid w-full items-end justify-start sm:justify-center",
        "before:block before:aspect-square before:w-full sm:before:hidden",
        className,
      )}
    >
      {link ? (
        <SanityLink
          parentIndex={rest.index}
          parentSection={rest._type}
          itemIndex={0}
          _ref={link?._ref}
          className="contents text-[0px] [&>:not(img)]:hidden"
        >
          {image && (
            <Image
              {...getImageProps({
                ...image,
                ...getImageAspectRatio({
                  height: image.asset.metadata.dimensions.height,
                  aspectRatio: image.asset.metadata.dimensions.aspectRatio,
                }),
              })}
              fill
              width={undefined}
              height={undefined}
              priority={priority}
              className={classNames("object-cover", {
                "lg:!left-[calc((100vw-1140px)/-2)] lg:!w-[100vw] lg:!max-w-none":
                  background?.container,
              })}
            />
          )}
        </SanityLink>
      ) : (
        image && (
          <Image
            {...getImageProps({
              ...image,
              ...getImageAspectRatio({
                height: image.asset.metadata.dimensions.height,
                aspectRatio: image.asset.metadata.dimensions.aspectRatio,
              }),
            })}
            fill
            width={undefined}
            height={undefined}
            priority={priority}
            className="object-cover"
          />
        )
      )}
      {children}
    </BlockContainer>
  );
};
