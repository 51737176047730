import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import classNames from "classnames";
import { BackgroundStyle } from "../types";
import { getThemeClassName } from "@/theming/selectors";
import { OverridableComponent } from "@/components/types";

export const BlockContainer: OverridableComponent<
  Partial<BackgroundStyle> & JSX.IntrinsicElements["section"]
> = ({
  container,
  variant,
  theme,
  children,
  className,
  style,
  as: Component = "section",
  experimentGroup,
  ...rest
}) => {
  return (
    <Component
      className={classNames(
        "relative overflow-hidden",
        {
          "my-section w-body mx-body rounded": container === "rounded",
          "sm:w-body sm:mx-body": container === "default",
          "lg:!overflow-visible": container === "fluid",
        },
        getThemeClassName(variant === "color" ? "primary" : null),
        className,
      )}
      style={{
        ...(variant === "color" ? getCSSPropertiesTheme(theme) : {}),
        ...style,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
