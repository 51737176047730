/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { BackgroundStyle } from "../types";
import { SanityImage } from "@/cms/images/types";
import { SectionProps } from "../Section";
import { ImageContentSectionCard } from "./ImageContentSectionCard";
import { ImageContentSectionCentered } from "./ImageContentSectionCentered";
import { ImageContentSectionFlat } from "./ImageContentSectionFlat";
import { OverridableComponent } from "@/components/types";

type ImageContentSectionProps = Partial<{
  variant: "card" | "flat" | "center";
  contentAlignment: "left" | "right";
  background: BackgroundStyle;
  image?: SanityImage;
  shortSection?: boolean;
}> &
  Pick<SectionProps, "index">;

export const ImageContentSection: OverridableComponent<
  ImageContentSectionProps & JSX.IntrinsicElements["section"]
> = ({ variant, children, as = "section", shortSection, ...props }) => {
  if (variant === "card")
    return (
      <ImageContentSectionCard {...props} as={as}>
        {children}
      </ImageContentSectionCard>
    );
  if (variant === "center")
    return (
      <ImageContentSectionCentered as={as} {...props}>
        {children}
      </ImageContentSectionCentered>
    );
  if (variant === "flat")
    return (
      <ImageContentSectionFlat as={as} {...props} shortSection={shortSection}>
        {children}
      </ImageContentSectionFlat>
    );
  return null;
};
